<template>
  <div class="modal-add-form-section">
    <div class="form-group">
      <div class="form-row">
        <div class="col-12">
          <div class="form-required-container">
            <textarea
              v-model="$parent.$parent.form.comment"
              class="partner-review-control mb-2"
              placeholder="Додаткова інформація"
              rows="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdditionInfo'
};
</script>
