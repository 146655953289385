<template>
  <div class="modal-add-form-section">
    <div class="form-group">
      <span class="sub-title">Поверх</span>
      <div class="form-row">
        <div class="col-6">
          <div class="form-required-container">
            <input
              v-model="$parent.$parent.form.floor_from"
              type="number"
              placeholder="від"
              class="form-input required"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-required-container">
            <input
              v-model="$parent.$parent.form.floor_to"
              type="number"
              placeholder="до"
              class="form-input required"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Floor'
};
</script>
