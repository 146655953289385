<template>
  <div class="col-6">
    <div class="form-group">
      <div class="form-required-container">
        <input
          type="text"
          placeholder="Номер будинку"
          class="form-input required"
          v-model="$parent.$parent.form.house_number"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HouseNumber'
};
</script>
