<template>
  <div class="modal-add-form-section">
    <div class="form-group col-12 subscrib-block">
      <span class="sub-title">
        Бюджет {{ $parent.$parent.form.business_type === 'sell' ? '$' : 'грн' }}
      </span>
      <div class="form-row">
        <div class="col-12">
          <div class="form-required-container">
            <input
              v-model="$parent.$parent.form.price_to"
              type="number"
              placeholder="до"
              class="form-input required"
            />
          </div>
        </div>
      </div>
      <p class="local-errors">
        <span class="form__alert">Допустимі лише цифри!</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Budget'
};
</script>
