<template>
  <b-modal
    id="add-search-popup"
    v-model="isOpenClientModal"
    modal-class="modal-add search-modal"
    :no-close-on-backdrop="true"
    :static="true"
    aria-labelledby="add-search-popup___BV_modal_title_"
  >
    <!-- header -->
    <div slot="modal-header">
      <h5 class="modal-add-title" id="add-search-popup___BV_modal_title_">
        Додати підписку
      </h5>
      <p class="modal-add-description">
        Необхідно заповнити обов'язкові поля
      </p>
    </div>

    <form slot="default" v-if="isOpenClientModal" action>
      <div class="modal-add-form-container" style="padding-bottom: 15px">
        <div class="modal-add-form-section">
          <icon name="edit_icon" class="modal-add-icon" />
        </div>

        <div class="modal-add-form-section">
          <div class="form-row">
            <div class="col-6">
              <icon name="required_icon" class="form-required-icon" />
              <Name />
            </div>
            <div class="col-6">
              <Phone />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-add-form-container">
        <div class="modal-add-form-section">
          <icon name="menu_icon1" class="modal-add-icon" />
        </div>

        <div class="modal-add-form-section">
          <div class="form-row form-group">
            <div class="col-6" v-if="!isEnabledSuburb">
              <ResidentialComplex />
            </div>
            <div class="col-6">
              <BusinessType />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-add-form-container popper">
        <div class="modal-add-form-section">
          <icon name="menu_icon1" class="modal-add-icon" />
        </div>

        <div class="modal-add-form-section">
          <div class="form-row">
            <Cities @select="changeCity()" v-model="form.city" />
            <District :isEnabledSuburb="isEnabledSuburb" />
            <Microdistrict v-if="!isEnabledSuburb" />
            <Street />
            <HouseNumber />
          </div>
        </div>
      </div>

      <div class="modal-add-form-container">
        <div class="modal-add-form-container w-100 form-group">
          <div class="modal-add-form-section">
            <icon name="menu_icon1_1" class="modal-add-icon" />
          </div>
          <div class="row w-100">
            <BuildingType />
            <Condition />
          </div>
        </div>
      </div>

      <div class="modal-add-form-container form-group">
        <div class="modal-add-form-section">
          <icon name="menu_icon2" class="modal-add-icon" />
        </div>
        <ApartmentType />
      </div>

      <div class="modal-add-form-container">
        <div class="modal-add-form-section">
          <icon name="menu_icon4" class="modal-add-icon" />
        </div>

        <div class="modal-add-form-section">
          <div class="form-group subscrib-block">
            <div class="form-row popper align-items-end">
              <NumberOfRooms />
              <TotalArea />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-content-container">
        <LandArea />
      </div>
      <div class="modal-content-container">
        <div class="row">
          <div class="col-6">
            <div class="modal-add-form-container">
              <div class="modal-add-form-section">
                <icon name="menu_icon3" class="modal-add-icon" />
              </div>
              <Floors />
            </div>
            <div class="modal-add-form-container" v-if="form.type === 'flat'">
              <div class="modal-add-form-section">
                <icon name="menu_icon2" class="modal-add-icon" />
              </div>
              <Floor />
            </div>

            <div class="modal-add-form-container">
              <div class="modal-add-form-section">
                <icon name="menu_icon6" class="modal-add-icon" />
              </div>
              <Budget />
            </div>
          </div>
          <div class="col-6">
            <p class="modal-add-description right-desc">
              Ми подбаємо про збереження внесених даних. Не зберігайте клієнтів на папері – тут надійніше!
            </p>
          </div>
        </div>
      </div>
      <div class="modal-add-form-container">
        <div class="modal-add-form-section">
          <icon name="menu_icon6" class="modal-add-icon" />
        </div>

        <AdditionInfo />
      </div>
    </form>

    <div slot="modal-footer">
      <button type="button" class="btn btn-filter-transparent" @click="cancel">
        Скасувати
      </button>

      <button
        class="btn btn-filter-transparent"
        style="width: 100px"
        :disabled="!form.client_name || $v.$invalid"
        @click="handleClient"
      >
        {{ this.activeClient ? 'Зберегти' : 'Додати' }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import api from '@/api';
import { formationClient } from '@/utils/client-builder';
import Name from './components/Name';
import Phone from './components/Phone';
import District from './components/District';
import Microdistrict from './components/Microdistrict';
import Street from './components/Street';
import ResidentialComplex from './components/ResidentialComplex';
import HouseNumber from './components/HouseNumber';
import BuildingType from './components/BuildingType';
import BusinessType from './components/BusinessType';
import Condition from './components/Condition';
import ApartmentType from './components/ApartmentType';
import NumberOfRooms from './components/NumberOfRooms';
import TotalArea from './components/TotalArea';
import LandArea from './components/LandArea';
import Floors from './components/Floors';
import Floor from './components/Floor';
import Budget from './components/Budget';
import AdditionInfo from './components/AdditionInfo';
import { mapState, mapMutations, mapActions } from 'vuex';
import LabelsMixin from '@/mixins/LabelsMixin';
import Cities from './components/cities';
import { required } from "vuelidate/lib/validators";

const initialForm = {
  client_name: '',
  client_phone: '',
  district: [],
  microdistrict: [],
  street: [],
  residential_complex: [],
  house_number: '',
  type: 'flat',
  repair: [],
  apt_type: [],
  is_angled: false,
  rooms: [],
  square_common_from: '',
  square_common_to: '',
  square_area_from: '',
  square_area_to: '',
  floors_from: '',
  floors_to: '',
  floor_from: '',
  floor_to: '',
  price_to: '',
  comment: '',
  business_type: 'sell',
  city: '',
};

export default {
  name: 'add-client-popup',
  mixins: [LabelsMixin],
  components: {
    Cities,
    Name,
    Phone,
    District,
    Microdistrict,
    Street,
    HouseNumber,
    BuildingType,
    Condition,
    ApartmentType,
    NumberOfRooms,
    TotalArea,
    LandArea,
    Floors,
    Floor,
    Budget,
    AdditionInfo,
    BusinessType,
    ResidentialComplex
  },
  data() {
    return {
      isOpenClientModal: false,
      form: { ...initialForm }
    };
  },
  validations() {
    return {
      form: {
        city: { required }
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_IS_OPEN_CLIENT_MODAL',
      'SET_GROUPS_CLIENT_FILTERS',
      'UPDATE_GROUP_CLIENT_FILTERS'
    ]),
    ...mapActions(['handleGetDistrictByCities']),
    cancel() {
      this.$bvModal.hide('add-search-popup');
    },
    changeCity() {
      this.form.district = [];
      this.form.microdistrict = []
    },
    handleClient() {
      if (this.activeClient) {
        this.editClient();
      } else {
        this.createClient();
      }
    },
    createClient() {
      api.createClient(formationClient({ ...this.form })).then((res) => {
        if (res.status === 201) {
          this.isOpenClientModal = false;
          this.form = { ...initialForm };
          this.SET_GROUPS_CLIENT_FILTERS([
            ...this.$store.state.clients.groupsClientFilters,
            res.data
          ]);
          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'success',
            text:
              "Клієнта додано! Натисніть на нього, щоб передивитись нові об'єкти!"
          });
        }
      });
    },
    editClient() {
      api
        .editClient(this.activeClient.id, formationClient({ ...this.form }))
        .then((res) => {
          if (res.status === 200) {
            this.isOpenClientModal = false;
            this.form = { ...initialForm };
            this.UPDATE_GROUP_CLIENT_FILTERS(res.data);
          }
        });
    },
    async getDistricts(id){
      if (id)
        await this.handleGetDistrictByCities(id)
    }
  },
  computed: {
    ...mapState({
      activeClient: (state) => state.clients.activeClient,
      microdistricts: (state) => state.addresses.microdistricts,
      districts: (state) => state.addresses.districts,
      streets: (state) => state.addresses.streets
    }),
    ...mapActions(['handleGetDistrict']),
    isEnabledSuburb() {
      if (this.activeClient) {
        this.getDistricts(this.activeClient.filter_value.city.id);
        return !this.activeClient.filter_value.city.is_city;
      }
    },
  },
  watch: {
    activeClient() {
      if (this.activeClient) {
        const activeClientForm = {
          ...this.activeClient.filter_value,
          ...{
            client_name: this.activeClient.client_name,
            client_phone: this.activeClient.client_phone,
            comment: this.activeClient.comment
          }
        };

        // parse microdistrict
        activeClientForm.microdistrict = this.microdistricts.filter((item) => {
          return activeClientForm.microdistrict.includes(item.id);
        });

        // parse district
        activeClientForm.district = this.districts.filter((item) => {
          return activeClientForm.district.includes(item.id);
        });

        // parse street
        activeClientForm.street = this.streets.filter((item) => {
          return activeClientForm.street.includes(item.id);
        });

        // parse repair
        activeClientForm.repair = this.choises_label.filter((item) => {
          return activeClientForm.repair.includes(item.label);
        });

        this.form = activeClientForm;
        this.isOpenClientModal = true;
      } else {
        this.form = { ...initialForm };
      }
    },
  }
};
</script>
