<template>
  <div class="col-6">
    <span class="sub-title">Загальна площа</span>
    <div class="form-row">
      <div class="col-6">
        <div class="form-required-container">
          <input
            v-model="$parent.$parent.form.square_common_from"
            type="text"
            placeholder="від"
            class="form-input required"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-required-container">
          <input
            v-model="$parent.$parent.form.square_common_to"
            type="number"
            placeholder="до"
            class="form-input required"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TotalArea'
};
</script>
