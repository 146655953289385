<template>
  <div class="col-6">
    <div class="form-group multiselect">
      <multiselect
        placeholder="Вулиця"
        label="name"
        track-by="id"
        :options="options"
        v-model="$parent.$parent.form.street"
        :multiple="true"
        :show-labels="false"
        @search-change="findStreets"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import api from '@/api';

export default {
  name: 'Street',
  components: {
    Multiselect
  },
  data() {
    return {
      options: []
    };
  },
  created() {
    this.options = this.$store.state.filter_address.streets;
  },
  methods: {
    findStreets(name) {
      if ( name.length > 0 && name.length < 3 ) return;

      api.handleGetStreet({name}).then(res => {
        this.options = res.data.results;
      });
    }
  }
};
</script>
