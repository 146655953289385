/**
 * Building params for create client
 * @param {array} payload
 * @returns {array}
 */
export const formationClient = (payload) => {
  for (const item in payload) {
    switch (item) {
      case 'district':
      case 'microdistrict':
      case 'street':
        payload[item] = payload[item].map((i) => i.id);
        break;
      case 'repair':
        payload[item] = payload[item].map((i) => i.label);
        break;
    }
  }

  return getStructure(payload);
};

/**
 * Create backend structure params
 * @param {payload}
 * @returns {array}
 */
const getStructure = ({ client_name, client_phone, comment, ...params }) => {
  return {
    client_name,
    client_phone,
    comment,
    filter_value: params
  };
};
