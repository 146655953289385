<template>
  <div>
    <popper
      ref="popover1"
      trigger="clickToToggle"
      class="modal-add-form-section"
      v-cloak
      :options="{
        placement: 'bottom-start',
        modifiers: { arrow: { enabled: false } }
      }"
    >
      <a href="#" slot="reference" class="d-flex w-100 form-required-container">
        <input
          class="form-input"
          type="text"
          placeholder="Тип нерухомості"
          readonly
          v-model="title"
        />
      </a>
      <div class="popper modal-add-dropdown-menu filter-modal">
        <div
          class="btn-group-toggle form-checkbox-b mb-2"
          v-for="(label, index) in ['Продам', 'Здам']"
          :key="index"
          data-toggle="buttons"
        >
          <input
            type="radio"
            class="form-radio"
            :value="index"
            @click="updateType(index)"
            :checked="checkedType(index)"
          />
          <label @click="updateType(index)" class="btn btn-row">
            <span>{{ label }}</span>
          </label>
        </div>
      </div>
    </popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs';

export default {
  name: 'BusinessType',
  components: {
    Popper
  },
  data() {
    return {
      types: ['sell', 'rent']
    };
  },
  methods: {
    updateType(index) {
      this.$parent.$parent.form.business_type = this.types[index];
      this.$refs.popover1.showPopper = false;
    },
    checkedType(index) {
      return this.$parent.$parent.form.business_type === this.types[index];
    }
  },
  computed: {
    title() {
      if (this.$parent.$parent.form.business_type === 'sell') {
        return 'Продам';
      }

      return 'Здам';
    }
  }
};
</script>
