<template>
  <popper
    trigger="click"
    class="modal-add-form-section"
    :options="{
      placement: 'bottom-start',
      modifiers: { arrow: { enabled: false } }
    }"
  >
    <a href="#" slot="reference" class="d-flex w-100">
      <div class="form-input">{{ getTypesSelected }}</div>
    </a>

    <div class="popper modal-add-dropdown-menu filter-modal">
      <div class="text-center form-group">
        {{ $parent.$parent.form.type === 'flat' ? 'Тип квартири' : 'Тип будинку' }}
      </div>
      <div class="px-0">
        <div class="form-row">
          <div
            class="col-12 d-flex flex-column justify-content-between apartment-list"
          >
            <div
              v-for="(item, index) in getItems()"
              class="form-group form-group-filter"
              :key="index"
            >
              <label class="btn pl-0">
                <input
                  type="checkbox"
                  :checked="false"
                  class="form-radio"
                  autocomplete="off"
                  v-model="$parent.$parent.form.apt_type"
                  :value="index"
                />
                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>
                <span style="padding-left: 5px">{{ item }}</span>
              </label>
            </div>
          </div>
          <div
            class="col-5 col-md-6 pl-1 btn-group-toggle"
            v-if="$parent.$parent.form.type === 'flat'"
          >
            <div class="btn-group-toggle form-checkbox-b" data-toggle="buttons">
              <label class="btn pl-0">
                <input
                  type="checkbox"
                  checked
                  autocomplete="off"
                  id="is_angled"
                  v-model="$parent.$parent.form.is_angled"
                />
                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>
                <span>Кутова</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs';
import LabelsMixin from '@/mixins/LabelsMixin';

export default {
  name: 'ApartmentType',
  mixins: [LabelsMixin],
  components: {
    Popper
  },
  methods: {
    getItems() {
      if (this.$parent.$parent.form.type === 'house') {
        return this.house_type;
      }

      return this.objects_types_double;
    }
  },
  computed: {
    getTypesSelected() {
      const items = this.getItems();
      const titles = this.$parent.$parent.form.apt_type.map(
        (type) => items[type]
      );

      if (!titles.length) {
        return this.$parent.$parent.form.type === 'flat'
          ? 'Тип квартири'
          : 'Тип будинку';
      }

      return titles.join(', ');
    }
  }
};
</script>

<style scoped>
.apartment-list {
  padding-left: 30px;
}
</style>
