<template>
  <div class="col-5 align-items-end">
    <span class="sub-title">Кіл-ть кімнат</span>
    <div class="form-group mb-0">
      <multiselect
        placeholder="кількість кімнат"
        :close-on-select="false"
        :clear-on-select="false"
        :show-no-results="false"
        :show-labels="false"
        :options="roomsSelect"
        :multiple="true"
        v-model="$parent.$parent.form.rooms"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'NumberOfRooms',
  components: {
    Multiselect
  },
  data() {
    return {
      roomsSelect: ['1', '2', '3', '4', '5+']
    };
  }
};
</script>
