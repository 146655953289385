<template>
  <div>
    <div class="form-required-container">
      <multiselect
        placeholder="ЖК"
        label="name"
        track-by="id"
        :options="options"
        v-model="$parent.$parent.form.residential_complex"
        :multiple="true"
        :show-labels="false"
        @search-change="findResidentialComplex"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import api from '@/api';

export default {
  name: 'ResidentialComplex',
  components: {
    Multiselect
  },
  data() {
    return {
      options: []
    };
  },
  created() {
    this.options = this.$store.state.filter_address.residentialComplex;
  },
  methods: {
    findResidentialComplex(name) {
      if ( name.length > 0 && name.length < 3 ) return;
      api.handleGetResidentialComplex({name}).then(res => {
        this.options = res.data.results;
      });
    }
  }
};
</script>
