<template>
  <div class="col-6">
    <div class="form-group multiselect">
      <multiselect
        :placeholder="isEnabledSuburb ? 'Район' : 'Админ район'"
        label="name"
        track-by="id"
        :options="districts"
        v-model="$parent.$parent.form.district"
        :multiple="true"
        :show-labels="false"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions, mapState } from 'vuex';
import api from '@/api';

export default {
  name: 'District',
  components: {
    Multiselect
  },

  props: {
    isEnabledSuburb: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      districts: (state) => state.addresses.districts
    })
  },
  created() {
    this.handleGetDistrict();
  },
  methods: {
    ...mapActions(['handleGetDistrict'])
  }
};
</script>
