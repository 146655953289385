<template>
  <input
    type="text"
    class="form-input"
    placeholder="Телефон"
    v-model="clientPhone"
  />
</template>

<script>
export default {
  name: 'Phone',
  computed: {
    clientPhone: {
      get() {
        return this.$parent.$parent.form.client_phone;
      },
      set(val) {
        this.$parent.$parent.form.client_phone = val;
      }
    }
  }
};
</script>
