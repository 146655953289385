<template>
  <div class="row">
    <div class="col-6">
      <div class="modal-add-form-container">
        <div class="modal-add-form-section">
          <icon name="menu_icon3" class="modal-add-icon" />
        </div>
        <div class="modal-add-form-section mb-3">
          <span class="sub-title">Площа ділянки </span>
          <div class="form-row">
            <div class="col-6">
              <div class="form-required-container">
                <input
                  v-model="$parent.$parent.form.square_area_from"
                  type="text"
                  placeholder="від"
                  class="form-input required"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-required-container">
                <input
                  v-model="$parent.$parent.form.square_area_to"
                  type="number"
                  placeholder="до"
                  class="form-input required"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandArea'
};
</script>
