<template>
  <div class="col-6">
    <div class="form-group">
      <div class="form-required-container">
        <multiselect
          v-model="$parent.$parent.form.repair"
          placeholder="Стан"
          label="name"
          track-by="label"
          :close-on-select="false"
          :clear-on-select="false"
          :show-no-results="false"
          :show-labels="false"
          :options="choises_label"
          :multiple="true"
        >
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import LabelsMixin from '@/mixins/LabelsMixin';
import Multiselect from 'vue-multiselect';

export default {
  name: 'Condition',
  mixins: [LabelsMixin],
  components: {
    Multiselect
  }
};
</script>
