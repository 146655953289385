<template>
  <div class="col-6">
    <div class="form-group multiselect">
      <icon name="required_icon" class="form-required-icon"/>
      <multiselect
        placeholder="Місто"
        label="name"
        track-by="id"
        :options="[...options, {id: 3, is_city: false, name: 'АР Kрим (скоро)',$isDisabled: true}]"
        v-model="$parent.$parent.form.city"
        @select="$emit('select')"
        :show-labels="false"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect';
import api from '@/api';

export default {
  name: 'cities',
  components: {multiselect},
  data() {
    return {
      options: []
    }
  },
  computed: {
    get() {
      return this.value;
    },
    set(val) {
      this.$emit('input', val)
    }
  },
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  created() {
    this.options = this.$store.state.filter_address.cities;
  }
};
</script>