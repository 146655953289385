<template>
  <input
    type="text"
    placeholder="Ім'я"
    class="form-input required"
    v-model="clientName"
  />
</template>

<script>
export default {
  name: 'Name',
  computed: {
    clientName: {
      get() {
        return this.$parent.$parent.form.client_name;
      },
      set(val) {
        this.$parent.$parent.form.client_name = val;
      }
    }
  }
};
</script>
