<template>
  <div class="col-6">
    <popper
      ref="popover1"
      trigger="clickToToggle"
      class="modal-add-form-section"
      v-cloak
      :options="{
        placement: 'bottom-start',
        modifiers: { arrow: { enabled: false } }
      }"
    >
      <a href="#" slot="reference" class="d-flex w-100 form-required-container">
        <input
          v-model="types[$parent.$parent.form.type]"
          class="form-input"
          type="text"
          placeholder="Тип нерухомості"
          readonly
        />
      </a>
      <div class="popper modal-add-dropdown-menu filter-modal">
        <div
          class="btn-group-toggle form-checkbox-b mb-2"
          v-for="(label, index) in this.types"
          :key="index"
          data-toggle="buttons"
        >
          <input
            type="radio"
            class="form-radio"
            :value="index"
            @click="updateType(index)"
            :checked="$parent.$parent.form.type === index"
          />
          <label @click="updateType(index)" class="btn btn-row">
            <span>
              {{ label }}
              <span class="status-options" v-if="index === 'commerce'">
                (скоро)
              </span>

              <span class="status-options" v-if="index === 'land'">
                (скоро)
              </span>

              <span class="status-options" v-if="index === 'garage'">
                (скоро)
              </span>
            </span>
          </label>
        </div>
      </div>
    </popper>
  </div>
</template>

<script>
import LabelsMixin from '@/mixins/LabelsMixin';
import Popper from 'vue-popperjs';

export default {
  name: 'BuildingType',
  mixins: [LabelsMixin],
  components: {
    Popper
  },
  methods: {
    updateType(type) {
      if (type === 'flat' || type === 'house') {
        this.$parent.$parent.form.type = type;
        this.$parent.$parent.form.apt_type = [];
        this.$parent.$parent.form.is_angled = false;
        this.$parent.$parent.form.floor_from = '';
        this.$parent.$parent.form.floor_to = '';

        this.$refs.popover1.showPopper = false;
      }
    }
  }
};
</script>
